import * as _nodeFetch2 from "node-fetch";

var _nodeFetch = "default" in _nodeFetch2 ? _nodeFetch2.default : _nodeFetch2;

import * as _crypto2 from "crypto";

var _crypto = "default" in _crypto2 ? _crypto2.default : _crypto2;

import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Buffer = _buffer.Buffer;
!function (e, t) {
  t(exports);
}(exports, function (e) {
  "use strict";

  function t(e, t) {
    if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
  }

  function r(e, t) {
    for (var r = 0; t.length > r; r++) {
      var i = t[r];
      i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(e, i.key, i);
    }
  }

  function i(e, t, i) {
    return t && r(e.prototype, t), i && r(e, i), e;
  }

  function s(e) {
    return (s = Object.setPrototypeOf ? Object.getPrototypeOf : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    })(e);
  }

  function n(e, t) {
    return (n = Object.setPrototypeOf || function (e, t) {
      return e.__proto__ = t, e;
    })(e, t);
  }

  function a() {
    if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
    if (Reflect.construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;

    try {
      return Date.prototype.toString.call(Reflect.construct(Date, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }

  function o(e, t, r) {
    return (o = a() ? Reflect.construct : function (e, t, r) {
      var i = [null];
      i.push.apply(i, t);
      var s = new (Function.bind.apply(e, i))();
      return r && n(s, r.prototype), s;
    }).apply(null, arguments);
  }

  function u(e) {
    var t = "function" == typeof Map ? new Map() : void 0;
    return (u = function (e) {
      if (null === e || -1 === Function.toString.call(e).indexOf("[native code]")) return e;
      if ("function" != typeof e) throw new TypeError("Super expression must either be null or a function");

      if (void 0 !== t) {
        if (t.has(e)) return t.get(e);
        t.set(e, r);
      }

      function r() {
        return o(e, arguments, s(this || _global).constructor);
      }

      return r.prototype = Object.create(e.prototype, {
        constructor: {
          value: r,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), n(r, e);
    })(e);
  }

  function c(e, t) {
    return !t || "object" != typeof t && "function" != typeof t ? function (e) {
      if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return e;
    }(e) : t;
  }

  var p = "app",
      l = "user",
      f = "team",
      m = "dropboxapi.com",
      h = "dropbox.com",
      d = {
    api: "api",
    notify: "bolt",
    content: "api-content"
  },
      _ = {};

  function g(e) {
    var t = "000".concat(e.charCodeAt(0).toString(16)).slice(-4);
    return "\\u".concat(t);
  }

  _.accountSetProfilePhoto = function (e) {
    return this.request("account/set_profile_photo", e, "user", "api", "rpc", "account_info.write");
  }, _.authTokenFromOauth1 = function (e) {
    return this.request("auth/token/from_oauth1", e, "app", "api", "rpc", null);
  }, _.authTokenRevoke = function () {
    return this.request("auth/token/revoke", null, "user", "api", "rpc", null);
  }, _.checkApp = function (e) {
    return this.request("check/app", e, "app", "api", "rpc", null);
  }, _.checkUser = function (e) {
    return this.request("check/user", e, "user", "api", "rpc", "account_info.read");
  }, _.contactsDeleteManualContacts = function () {
    return this.request("contacts/delete_manual_contacts", null, "user", "api", "rpc", "contacts.write");
  }, _.contactsDeleteManualContactsBatch = function (e) {
    return this.request("contacts/delete_manual_contacts_batch", e, "user", "api", "rpc", "contacts.write");
  }, _.filePropertiesPropertiesAdd = function (e) {
    return this.request("file_properties/properties/add", e, "user", "api", "rpc", "files.metadata.write");
  }, _.filePropertiesPropertiesOverwrite = function (e) {
    return this.request("file_properties/properties/overwrite", e, "user", "api", "rpc", "files.metadata.write");
  }, _.filePropertiesPropertiesRemove = function (e) {
    return this.request("file_properties/properties/remove", e, "user", "api", "rpc", "files.metadata.write");
  }, _.filePropertiesPropertiesSearch = function (e) {
    return this.request("file_properties/properties/search", e, "user", "api", "rpc", "files.metadata.read");
  }, _.filePropertiesPropertiesSearchContinue = function (e) {
    return this.request("file_properties/properties/search/continue", e, "user", "api", "rpc", "files.metadata.read");
  }, _.filePropertiesPropertiesUpdate = function (e) {
    return this.request("file_properties/properties/update", e, "user", "api", "rpc", "files.metadata.write");
  }, _.filePropertiesTemplatesAddForTeam = function (e) {
    return this.request("file_properties/templates/add_for_team", e, "team", "api", "rpc", "files.team_metadata.write");
  }, _.filePropertiesTemplatesAddForUser = function (e) {
    return this.request("file_properties/templates/add_for_user", e, "user", "api", "rpc", "files.metadata.write");
  }, _.filePropertiesTemplatesGetForTeam = function (e) {
    return this.request("file_properties/templates/get_for_team", e, "team", "api", "rpc", "files.team_metadata.write");
  }, _.filePropertiesTemplatesGetForUser = function (e) {
    return this.request("file_properties/templates/get_for_user", e, "user", "api", "rpc", "files.metadata.read");
  }, _.filePropertiesTemplatesListForTeam = function () {
    return this.request("file_properties/templates/list_for_team", null, "team", "api", "rpc", "files.team_metadata.write");
  }, _.filePropertiesTemplatesListForUser = function () {
    return this.request("file_properties/templates/list_for_user", null, "user", "api", "rpc", "files.metadata.read");
  }, _.filePropertiesTemplatesRemoveForTeam = function (e) {
    return this.request("file_properties/templates/remove_for_team", e, "team", "api", "rpc", "files.team_metadata.write");
  }, _.filePropertiesTemplatesRemoveForUser = function (e) {
    return this.request("file_properties/templates/remove_for_user", e, "user", "api", "rpc", "files.metadata.write");
  }, _.filePropertiesTemplatesUpdateForTeam = function (e) {
    return this.request("file_properties/templates/update_for_team", e, "team", "api", "rpc", "files.team_metadata.write");
  }, _.filePropertiesTemplatesUpdateForUser = function (e) {
    return this.request("file_properties/templates/update_for_user", e, "user", "api", "rpc", "files.metadata.write");
  }, _.fileRequestsCount = function () {
    return this.request("file_requests/count", null, "user", "api", "rpc", "file_requests.read");
  }, _.fileRequestsCreate = function (e) {
    return this.request("file_requests/create", e, "user", "api", "rpc", "file_requests.write");
  }, _.fileRequestsDelete = function (e) {
    return this.request("file_requests/delete", e, "user", "api", "rpc", "file_requests.write");
  }, _.fileRequestsDeleteAllClosed = function () {
    return this.request("file_requests/delete_all_closed", null, "user", "api", "rpc", "file_requests.write");
  }, _.fileRequestsGet = function (e) {
    return this.request("file_requests/get", e, "user", "api", "rpc", "file_requests.read");
  }, _.fileRequestsListV2 = function (e) {
    return this.request("file_requests/list_v2", e, "user", "api", "rpc", "file_requests.read");
  }, _.fileRequestsList = function () {
    return this.request("file_requests/list", null, "user", "api", "rpc", "file_requests.read");
  }, _.fileRequestsListContinue = function (e) {
    return this.request("file_requests/list/continue", e, "user", "api", "rpc", "file_requests.read");
  }, _.fileRequestsUpdate = function (e) {
    return this.request("file_requests/update", e, "user", "api", "rpc", "file_requests.write");
  }, _.filesAlphaGetMetadata = function (e) {
    return this.request("files/alpha/get_metadata", e, "user", "api", "rpc", "files.metadata.read");
  }, _.filesAlphaUpload = function (e) {
    return this.request("files/alpha/upload", e, "user", "content", "upload", "files.content.write");
  }, _.filesCopyV2 = function (e) {
    return this.request("files/copy_v2", e, "user", "api", "rpc", "files.content.write");
  }, _.filesCopy = function (e) {
    return this.request("files/copy", e, "user", "api", "rpc", "files.content.write");
  }, _.filesCopyBatchV2 = function (e) {
    return this.request("files/copy_batch_v2", e, "user", "api", "rpc", "files.content.write");
  }, _.filesCopyBatch = function (e) {
    return this.request("files/copy_batch", e, "user", "api", "rpc", "files.content.write");
  }, _.filesCopyBatchCheckV2 = function (e) {
    return this.request("files/copy_batch/check_v2", e, "user", "api", "rpc", "files.content.write");
  }, _.filesCopyBatchCheck = function (e) {
    return this.request("files/copy_batch/check", e, "user", "api", "rpc", "files.content.write");
  }, _.filesCopyReferenceGet = function (e) {
    return this.request("files/copy_reference/get", e, "user", "api", "rpc", "files.content.write");
  }, _.filesCopyReferenceSave = function (e) {
    return this.request("files/copy_reference/save", e, "user", "api", "rpc", "files.content.write");
  }, _.filesCreateFolderV2 = function (e) {
    return this.request("files/create_folder_v2", e, "user", "api", "rpc", "files.content.write");
  }, _.filesCreateFolder = function (e) {
    return this.request("files/create_folder", e, "user", "api", "rpc", "files.content.write");
  }, _.filesCreateFolderBatch = function (e) {
    return this.request("files/create_folder_batch", e, "user", "api", "rpc", "files.content.write");
  }, _.filesCreateFolderBatchCheck = function (e) {
    return this.request("files/create_folder_batch/check", e, "user", "api", "rpc", "files.content.write");
  }, _.filesDeleteV2 = function (e) {
    return this.request("files/delete_v2", e, "user", "api", "rpc", "files.content.write");
  }, _.filesDelete = function (e) {
    return this.request("files/delete", e, "user", "api", "rpc", "files.content.write");
  }, _.filesDeleteBatch = function (e) {
    return this.request("files/delete_batch", e, "user", "api", "rpc", "files.content.write");
  }, _.filesDeleteBatchCheck = function (e) {
    return this.request("files/delete_batch/check", e, "user", "api", "rpc", "files.content.write");
  }, _.filesDownload = function (e) {
    return this.request("files/download", e, "user", "content", "download", "files.content.read");
  }, _.filesDownloadZip = function (e) {
    return this.request("files/download_zip", e, "user", "content", "download", "files.content.read");
  }, _.filesExport = function (e) {
    return this.request("files/export", e, "user", "content", "download", "files.content.read");
  }, _.filesGetFileLockBatch = function (e) {
    return this.request("files/get_file_lock_batch", e, "user", "api", "rpc", "files.content.read");
  }, _.filesGetMetadata = function (e) {
    return this.request("files/get_metadata", e, "user", "api", "rpc", "files.metadata.read");
  }, _.filesGetPreview = function (e) {
    return this.request("files/get_preview", e, "user", "content", "download", "files.content.read");
  }, _.filesGetTemporaryLink = function (e) {
    return this.request("files/get_temporary_link", e, "user", "api", "rpc", "files.content.read");
  }, _.filesGetTemporaryUploadLink = function (e) {
    return this.request("files/get_temporary_upload_link", e, "user", "api", "rpc", "files.content.write");
  }, _.filesGetThumbnail = function (e) {
    return this.request("files/get_thumbnail", e, "user", "content", "download", "files.content.read");
  }, _.filesGetThumbnailV2 = function (e) {
    return this.request("files/get_thumbnail_v2", e, "app, user", "content", "download", "files.content.read");
  }, _.filesGetThumbnailBatch = function (e) {
    return this.request("files/get_thumbnail_batch", e, "user", "content", "rpc", "files.content.read");
  }, _.filesListFolder = function (e) {
    return this.request("files/list_folder", e, "app, user", "api", "rpc", "files.metadata.read");
  }, _.filesListFolderContinue = function (e) {
    return this.request("files/list_folder/continue", e, "app, user", "api", "rpc", "files.metadata.read");
  }, _.filesListFolderGetLatestCursor = function (e) {
    return this.request("files/list_folder/get_latest_cursor", e, "user", "api", "rpc", "files.metadata.read");
  }, _.filesListFolderLongpoll = function (e) {
    return this.request("files/list_folder/longpoll", e, "noauth", "notify", "rpc", "files.metadata.read");
  }, _.filesListRevisions = function (e) {
    return this.request("files/list_revisions", e, "user", "api", "rpc", "files.metadata.read");
  }, _.filesLockFileBatch = function (e) {
    return this.request("files/lock_file_batch", e, "user", "api", "rpc", "files.content.write");
  }, _.filesMoveV2 = function (e) {
    return this.request("files/move_v2", e, "user", "api", "rpc", "files.content.write");
  }, _.filesMove = function (e) {
    return this.request("files/move", e, "user", "api", "rpc", "files.content.write");
  }, _.filesMoveBatchV2 = function (e) {
    return this.request("files/move_batch_v2", e, "user", "api", "rpc", "files.content.write");
  }, _.filesMoveBatch = function (e) {
    return this.request("files/move_batch", e, "user", "api", "rpc", "files.content.write");
  }, _.filesMoveBatchCheckV2 = function (e) {
    return this.request("files/move_batch/check_v2", e, "user", "api", "rpc", "files.content.write");
  }, _.filesMoveBatchCheck = function (e) {
    return this.request("files/move_batch/check", e, "user", "api", "rpc", "files.content.write");
  }, _.filesPaperCreate = function (e) {
    return this.request("files/paper/create", e, "user", "api", "upload", "files.content.write");
  }, _.filesPaperUpdate = function (e) {
    return this.request("files/paper/update", e, "user", "api", "upload", "files.content.write");
  }, _.filesPermanentlyDelete = function (e) {
    return this.request("files/permanently_delete", e, "user", "api", "rpc", "files.permanent_delete");
  }, _.filesPropertiesAdd = function (e) {
    return this.request("files/properties/add", e, "user", "api", "rpc", "files.metadata.write");
  }, _.filesPropertiesOverwrite = function (e) {
    return this.request("files/properties/overwrite", e, "user", "api", "rpc", "files.metadata.write");
  }, _.filesPropertiesRemove = function (e) {
    return this.request("files/properties/remove", e, "user", "api", "rpc", "files.metadata.write");
  }, _.filesPropertiesTemplateGet = function (e) {
    return this.request("files/properties/template/get", e, "user", "api", "rpc", "files.metadata.read");
  }, _.filesPropertiesTemplateList = function () {
    return this.request("files/properties/template/list", null, "user", "api", "rpc", "files.metadata.read");
  }, _.filesPropertiesUpdate = function (e) {
    return this.request("files/properties/update", e, "user", "api", "rpc", "files.metadata.write");
  }, _.filesRestore = function (e) {
    return this.request("files/restore", e, "user", "api", "rpc", "files.content.write");
  }, _.filesSaveUrl = function (e) {
    return this.request("files/save_url", e, "user", "api", "rpc", "files.content.write");
  }, _.filesSaveUrlCheckJobStatus = function (e) {
    return this.request("files/save_url/check_job_status", e, "user", "api", "rpc", "files.content.write");
  }, _.filesSearch = function (e) {
    return this.request("files/search", e, "user", "api", "rpc", "files.metadata.read");
  }, _.filesSearchV2 = function (e) {
    return this.request("files/search_v2", e, "user", "api", "rpc", "files.metadata.read");
  }, _.filesSearchContinueV2 = function (e) {
    return this.request("files/search/continue_v2", e, "user", "api", "rpc", "files.metadata.read");
  }, _.filesTagsAdd = function (e) {
    return this.request("files/tags/add", e, "user", "api", "rpc", "files.metadata.write");
  }, _.filesTagsGet = function (e) {
    return this.request("files/tags/get", e, "user", "api", "rpc", "files.metadata.read");
  }, _.filesTagsRemove = function (e) {
    return this.request("files/tags/remove", e, "user", "api", "rpc", "files.metadata.write");
  }, _.filesUnlockFileBatch = function (e) {
    return this.request("files/unlock_file_batch", e, "user", "api", "rpc", "files.content.write");
  }, _.filesUpload = function (e) {
    return this.request("files/upload", e, "user", "content", "upload", "files.content.write");
  }, _.filesUploadSessionAppendV2 = function (e) {
    return this.request("files/upload_session/append_v2", e, "user", "content", "upload", "files.content.write");
  }, _.filesUploadSessionAppend = function (e) {
    return this.request("files/upload_session/append", e, "user", "content", "upload", "files.content.write");
  }, _.filesUploadSessionFinish = function (e) {
    return this.request("files/upload_session/finish", e, "user", "content", "upload", "files.content.write");
  }, _.filesUploadSessionFinishBatch = function (e) {
    return this.request("files/upload_session/finish_batch", e, "user", "api", "rpc", "files.content.write");
  }, _.filesUploadSessionFinishBatchV2 = function (e) {
    return this.request("files/upload_session/finish_batch_v2", e, "user", "api", "rpc", "files.content.write");
  }, _.filesUploadSessionFinishBatchCheck = function (e) {
    return this.request("files/upload_session/finish_batch/check", e, "user", "api", "rpc", "files.content.write");
  }, _.filesUploadSessionStart = function (e) {
    return this.request("files/upload_session/start", e, "user", "content", "upload", "files.content.write");
  }, _.filesUploadSessionStartBatch = function (e) {
    return this.request("files/upload_session/start_batch", e, "user", "api", "rpc", "files.content.write");
  }, _.openidUserinfo = function (e) {
    return this.request("openid/userinfo", e, "user", "api", "rpc", "openid");
  }, _.paperDocsArchive = function (e) {
    return this.request("paper/docs/archive", e, "user", "api", "rpc", "files.content.write");
  }, _.paperDocsCreate = function (e) {
    return this.request("paper/docs/create", e, "user", "api", "upload", "files.content.write");
  }, _.paperDocsDownload = function (e) {
    return this.request("paper/docs/download", e, "user", "api", "download", "files.content.read");
  }, _.paperDocsFolderUsersList = function (e) {
    return this.request("paper/docs/folder_users/list", e, "user", "api", "rpc", "sharing.read");
  }, _.paperDocsFolderUsersListContinue = function (e) {
    return this.request("paper/docs/folder_users/list/continue", e, "user", "api", "rpc", "sharing.read");
  }, _.paperDocsGetFolderInfo = function (e) {
    return this.request("paper/docs/get_folder_info", e, "user", "api", "rpc", "sharing.read");
  }, _.paperDocsList = function (e) {
    return this.request("paper/docs/list", e, "user", "api", "rpc", "files.metadata.read");
  }, _.paperDocsListContinue = function (e) {
    return this.request("paper/docs/list/continue", e, "user", "api", "rpc", "files.metadata.read");
  }, _.paperDocsPermanentlyDelete = function (e) {
    return this.request("paper/docs/permanently_delete", e, "user", "api", "rpc", "files.permanent_delete");
  }, _.paperDocsSharingPolicyGet = function (e) {
    return this.request("paper/docs/sharing_policy/get", e, "user", "api", "rpc", "sharing.read");
  }, _.paperDocsSharingPolicySet = function (e) {
    return this.request("paper/docs/sharing_policy/set", e, "user", "api", "rpc", "sharing.write");
  }, _.paperDocsUpdate = function (e) {
    return this.request("paper/docs/update", e, "user", "api", "upload", "files.content.write");
  }, _.paperDocsUsersAdd = function (e) {
    return this.request("paper/docs/users/add", e, "user", "api", "rpc", "sharing.write");
  }, _.paperDocsUsersList = function (e) {
    return this.request("paper/docs/users/list", e, "user", "api", "rpc", "sharing.read");
  }, _.paperDocsUsersListContinue = function (e) {
    return this.request("paper/docs/users/list/continue", e, "user", "api", "rpc", "sharing.read");
  }, _.paperDocsUsersRemove = function (e) {
    return this.request("paper/docs/users/remove", e, "user", "api", "rpc", "sharing.write");
  }, _.paperFoldersCreate = function (e) {
    return this.request("paper/folders/create", e, "user", "api", "rpc", "files.content.write");
  }, _.sharingAddFileMember = function (e) {
    return this.request("sharing/add_file_member", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingAddFolderMember = function (e) {
    return this.request("sharing/add_folder_member", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingCheckJobStatus = function (e) {
    return this.request("sharing/check_job_status", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingCheckRemoveMemberJobStatus = function (e) {
    return this.request("sharing/check_remove_member_job_status", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingCheckShareJobStatus = function (e) {
    return this.request("sharing/check_share_job_status", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingCreateSharedLink = function (e) {
    return this.request("sharing/create_shared_link", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingCreateSharedLinkWithSettings = function (e) {
    return this.request("sharing/create_shared_link_with_settings", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingGetFileMetadata = function (e) {
    return this.request("sharing/get_file_metadata", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingGetFileMetadataBatch = function (e) {
    return this.request("sharing/get_file_metadata/batch", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingGetFolderMetadata = function (e) {
    return this.request("sharing/get_folder_metadata", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingGetSharedLinkFile = function (e) {
    return this.request("sharing/get_shared_link_file", e, "user", "content", "download", "sharing.read");
  }, _.sharingGetSharedLinkMetadata = function (e) {
    return this.request("sharing/get_shared_link_metadata", e, "app, user", "api", "rpc", "sharing.read");
  }, _.sharingGetSharedLinks = function (e) {
    return this.request("sharing/get_shared_links", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingListFileMembers = function (e) {
    return this.request("sharing/list_file_members", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingListFileMembersBatch = function (e) {
    return this.request("sharing/list_file_members/batch", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingListFileMembersContinue = function (e) {
    return this.request("sharing/list_file_members/continue", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingListFolderMembers = function (e) {
    return this.request("sharing/list_folder_members", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingListFolderMembersContinue = function (e) {
    return this.request("sharing/list_folder_members/continue", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingListFolders = function (e) {
    return this.request("sharing/list_folders", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingListFoldersContinue = function (e) {
    return this.request("sharing/list_folders/continue", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingListMountableFolders = function (e) {
    return this.request("sharing/list_mountable_folders", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingListMountableFoldersContinue = function (e) {
    return this.request("sharing/list_mountable_folders/continue", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingListReceivedFiles = function (e) {
    return this.request("sharing/list_received_files", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingListReceivedFilesContinue = function (e) {
    return this.request("sharing/list_received_files/continue", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingListSharedLinks = function (e) {
    return this.request("sharing/list_shared_links", e, "user", "api", "rpc", "sharing.read");
  }, _.sharingModifySharedLinkSettings = function (e) {
    return this.request("sharing/modify_shared_link_settings", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingMountFolder = function (e) {
    return this.request("sharing/mount_folder", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingRelinquishFileMembership = function (e) {
    return this.request("sharing/relinquish_file_membership", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingRelinquishFolderMembership = function (e) {
    return this.request("sharing/relinquish_folder_membership", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingRemoveFileMember = function (e) {
    return this.request("sharing/remove_file_member", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingRemoveFileMember2 = function (e) {
    return this.request("sharing/remove_file_member_2", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingRemoveFolderMember = function (e) {
    return this.request("sharing/remove_folder_member", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingRevokeSharedLink = function (e) {
    return this.request("sharing/revoke_shared_link", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingSetAccessInheritance = function (e) {
    return this.request("sharing/set_access_inheritance", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingShareFolder = function (e) {
    return this.request("sharing/share_folder", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingTransferFolder = function (e) {
    return this.request("sharing/transfer_folder", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingUnmountFolder = function (e) {
    return this.request("sharing/unmount_folder", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingUnshareFile = function (e) {
    return this.request("sharing/unshare_file", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingUnshareFolder = function (e) {
    return this.request("sharing/unshare_folder", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingUpdateFileMember = function (e) {
    return this.request("sharing/update_file_member", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingUpdateFolderMember = function (e) {
    return this.request("sharing/update_folder_member", e, "user", "api", "rpc", "sharing.write");
  }, _.sharingUpdateFolderPolicy = function (e) {
    return this.request("sharing/update_folder_policy", e, "user", "api", "rpc", "sharing.write");
  }, _.teamDevicesListMemberDevices = function (e) {
    return this.request("team/devices/list_member_devices", e, "team", "api", "rpc", "sessions.list");
  }, _.teamDevicesListMembersDevices = function (e) {
    return this.request("team/devices/list_members_devices", e, "team", "api", "rpc", "sessions.list");
  }, _.teamDevicesListTeamDevices = function (e) {
    return this.request("team/devices/list_team_devices", e, "team", "api", "rpc", "sessions.list");
  }, _.teamDevicesRevokeDeviceSession = function (e) {
    return this.request("team/devices/revoke_device_session", e, "team", "api", "rpc", "sessions.modify");
  }, _.teamDevicesRevokeDeviceSessionBatch = function (e) {
    return this.request("team/devices/revoke_device_session_batch", e, "team", "api", "rpc", "sessions.modify");
  }, _.teamFeaturesGetValues = function (e) {
    return this.request("team/features/get_values", e, "team", "api", "rpc", "team_info.read");
  }, _.teamGetInfo = function () {
    return this.request("team/get_info", null, "team", "api", "rpc", "team_info.read");
  }, _.teamGroupsCreate = function (e) {
    return this.request("team/groups/create", e, "team", "api", "rpc", "groups.write");
  }, _.teamGroupsDelete = function (e) {
    return this.request("team/groups/delete", e, "team", "api", "rpc", "groups.write");
  }, _.teamGroupsGetInfo = function (e) {
    return this.request("team/groups/get_info", e, "team", "api", "rpc", "groups.read");
  }, _.teamGroupsJobStatusGet = function (e) {
    return this.request("team/groups/job_status/get", e, "team", "api", "rpc", "groups.write");
  }, _.teamGroupsList = function (e) {
    return this.request("team/groups/list", e, "team", "api", "rpc", "groups.read");
  }, _.teamGroupsListContinue = function (e) {
    return this.request("team/groups/list/continue", e, "team", "api", "rpc", "groups.read");
  }, _.teamGroupsMembersAdd = function (e) {
    return this.request("team/groups/members/add", e, "team", "api", "rpc", "groups.write");
  }, _.teamGroupsMembersList = function (e) {
    return this.request("team/groups/members/list", e, "team", "api", "rpc", "groups.read");
  }, _.teamGroupsMembersListContinue = function (e) {
    return this.request("team/groups/members/list/continue", e, "team", "api", "rpc", "groups.read");
  }, _.teamGroupsMembersRemove = function (e) {
    return this.request("team/groups/members/remove", e, "team", "api", "rpc", "groups.write");
  }, _.teamGroupsMembersSetAccessType = function (e) {
    return this.request("team/groups/members/set_access_type", e, "team", "api", "rpc", "groups.write");
  }, _.teamGroupsUpdate = function (e) {
    return this.request("team/groups/update", e, "team", "api", "rpc", "groups.write");
  }, _.teamLegalHoldsCreatePolicy = function (e) {
    return this.request("team/legal_holds/create_policy", e, "team", "api", "rpc", "team_data.governance.write");
  }, _.teamLegalHoldsGetPolicy = function (e) {
    return this.request("team/legal_holds/get_policy", e, "team", "api", "rpc", "team_data.governance.write");
  }, _.teamLegalHoldsListHeldRevisions = function (e) {
    return this.request("team/legal_holds/list_held_revisions", e, "team", "api", "rpc", "team_data.governance.write");
  }, _.teamLegalHoldsListHeldRevisionsContinue = function (e) {
    return this.request("team/legal_holds/list_held_revisions_continue", e, "team", "api", "rpc", "team_data.governance.write");
  }, _.teamLegalHoldsListPolicies = function (e) {
    return this.request("team/legal_holds/list_policies", e, "team", "api", "rpc", "team_data.governance.write");
  }, _.teamLegalHoldsReleasePolicy = function (e) {
    return this.request("team/legal_holds/release_policy", e, "team", "api", "rpc", "team_data.governance.write");
  }, _.teamLegalHoldsUpdatePolicy = function (e) {
    return this.request("team/legal_holds/update_policy", e, "team", "api", "rpc", "team_data.governance.write");
  }, _.teamLinkedAppsListMemberLinkedApps = function (e) {
    return this.request("team/linked_apps/list_member_linked_apps", e, "team", "api", "rpc", "sessions.list");
  }, _.teamLinkedAppsListMembersLinkedApps = function (e) {
    return this.request("team/linked_apps/list_members_linked_apps", e, "team", "api", "rpc", "sessions.list");
  }, _.teamLinkedAppsListTeamLinkedApps = function (e) {
    return this.request("team/linked_apps/list_team_linked_apps", e, "team", "api", "rpc", "sessions.list");
  }, _.teamLinkedAppsRevokeLinkedApp = function (e) {
    return this.request("team/linked_apps/revoke_linked_app", e, "team", "api", "rpc", "sessions.modify");
  }, _.teamLinkedAppsRevokeLinkedAppBatch = function (e) {
    return this.request("team/linked_apps/revoke_linked_app_batch", e, "team", "api", "rpc", "sessions.modify");
  }, _.teamMemberSpaceLimitsExcludedUsersAdd = function (e) {
    return this.request("team/member_space_limits/excluded_users/add", e, "team", "api", "rpc", "members.write");
  }, _.teamMemberSpaceLimitsExcludedUsersList = function (e) {
    return this.request("team/member_space_limits/excluded_users/list", e, "team", "api", "rpc", "members.read");
  }, _.teamMemberSpaceLimitsExcludedUsersListContinue = function (e) {
    return this.request("team/member_space_limits/excluded_users/list/continue", e, "team", "api", "rpc", "members.read");
  }, _.teamMemberSpaceLimitsExcludedUsersRemove = function (e) {
    return this.request("team/member_space_limits/excluded_users/remove", e, "team", "api", "rpc", "members.write");
  }, _.teamMemberSpaceLimitsGetCustomQuota = function (e) {
    return this.request("team/member_space_limits/get_custom_quota", e, "team", "api", "rpc", "members.read");
  }, _.teamMemberSpaceLimitsRemoveCustomQuota = function (e) {
    return this.request("team/member_space_limits/remove_custom_quota", e, "team", "api", "rpc", "members.write");
  }, _.teamMemberSpaceLimitsSetCustomQuota = function (e) {
    return this.request("team/member_space_limits/set_custom_quota", e, "team", "api", "rpc", "members.read");
  }, _.teamMembersAddV2 = function (e) {
    return this.request("team/members/add_v2", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersAdd = function (e) {
    return this.request("team/members/add", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersAddJobStatusGetV2 = function (e) {
    return this.request("team/members/add/job_status/get_v2", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersAddJobStatusGet = function (e) {
    return this.request("team/members/add/job_status/get", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersDeleteProfilePhotoV2 = function (e) {
    return this.request("team/members/delete_profile_photo_v2", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersDeleteProfilePhoto = function (e) {
    return this.request("team/members/delete_profile_photo", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersGetAvailableTeamMemberRoles = function () {
    return this.request("team/members/get_available_team_member_roles", null, "team", "api", "rpc", "members.read");
  }, _.teamMembersGetInfoV2 = function (e) {
    return this.request("team/members/get_info_v2", e, "team", "api", "rpc", "members.read");
  }, _.teamMembersGetInfo = function (e) {
    return this.request("team/members/get_info", e, "team", "api", "rpc", "members.read");
  }, _.teamMembersListV2 = function (e) {
    return this.request("team/members/list_v2", e, "team", "api", "rpc", "members.read");
  }, _.teamMembersList = function (e) {
    return this.request("team/members/list", e, "team", "api", "rpc", "members.read");
  }, _.teamMembersListContinueV2 = function (e) {
    return this.request("team/members/list/continue_v2", e, "team", "api", "rpc", "members.read");
  }, _.teamMembersListContinue = function (e) {
    return this.request("team/members/list/continue", e, "team", "api", "rpc", "members.read");
  }, _.teamMembersMoveFormerMemberFiles = function (e) {
    return this.request("team/members/move_former_member_files", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersMoveFormerMemberFilesJobStatusCheck = function (e) {
    return this.request("team/members/move_former_member_files/job_status/check", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersRecover = function (e) {
    return this.request("team/members/recover", e, "team", "api", "rpc", "members.delete");
  }, _.teamMembersRemove = function (e) {
    return this.request("team/members/remove", e, "team", "api", "rpc", "members.delete");
  }, _.teamMembersRemoveJobStatusGet = function (e) {
    return this.request("team/members/remove/job_status/get", e, "team", "api", "rpc", "members.delete");
  }, _.teamMembersSecondaryEmailsAdd = function (e) {
    return this.request("team/members/secondary_emails/add", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersSecondaryEmailsDelete = function (e) {
    return this.request("team/members/secondary_emails/delete", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersSecondaryEmailsResendVerificationEmails = function (e) {
    return this.request("team/members/secondary_emails/resend_verification_emails", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersSendWelcomeEmail = function (e) {
    return this.request("team/members/send_welcome_email", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersSetAdminPermissionsV2 = function (e) {
    return this.request("team/members/set_admin_permissions_v2", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersSetAdminPermissions = function (e) {
    return this.request("team/members/set_admin_permissions", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersSetProfileV2 = function (e) {
    return this.request("team/members/set_profile_v2", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersSetProfile = function (e) {
    return this.request("team/members/set_profile", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersSetProfilePhotoV2 = function (e) {
    return this.request("team/members/set_profile_photo_v2", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersSetProfilePhoto = function (e) {
    return this.request("team/members/set_profile_photo", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersSuspend = function (e) {
    return this.request("team/members/suspend", e, "team", "api", "rpc", "members.write");
  }, _.teamMembersUnsuspend = function (e) {
    return this.request("team/members/unsuspend", e, "team", "api", "rpc", "members.write");
  }, _.teamNamespacesList = function (e) {
    return this.request("team/namespaces/list", e, "team", "api", "rpc", "team_data.member");
  }, _.teamNamespacesListContinue = function (e) {
    return this.request("team/namespaces/list/continue", e, "team", "api", "rpc", "team_data.member");
  }, _.teamPropertiesTemplateAdd = function (e) {
    return this.request("team/properties/template/add", e, "team", "api", "rpc", "files.team_metadata.write");
  }, _.teamPropertiesTemplateGet = function (e) {
    return this.request("team/properties/template/get", e, "team", "api", "rpc", "files.team_metadata.write");
  }, _.teamPropertiesTemplateList = function () {
    return this.request("team/properties/template/list", null, "team", "api", "rpc", "files.team_metadata.write");
  }, _.teamPropertiesTemplateUpdate = function (e) {
    return this.request("team/properties/template/update", e, "team", "api", "rpc", "files.team_metadata.write");
  }, _.teamReportsGetActivity = function (e) {
    return this.request("team/reports/get_activity", e, "team", "api", "rpc", "team_info.read");
  }, _.teamReportsGetDevices = function (e) {
    return this.request("team/reports/get_devices", e, "team", "api", "rpc", "team_info.read");
  }, _.teamReportsGetMembership = function (e) {
    return this.request("team/reports/get_membership", e, "team", "api", "rpc", "team_info.read");
  }, _.teamReportsGetStorage = function (e) {
    return this.request("team/reports/get_storage", e, "team", "api", "rpc", "team_info.read");
  }, _.teamSharingAllowlistAdd = function (e) {
    return this.request("team/sharing_allowlist/add", e, "team", "api", "rpc", "team_info.write");
  }, _.teamSharingAllowlistList = function (e) {
    return this.request("team/sharing_allowlist/list", e, "team", "api", "rpc", "team_info.read");
  }, _.teamSharingAllowlistListContinue = function (e) {
    return this.request("team/sharing_allowlist/list/continue", e, "team", "api", "rpc", "team_info.read");
  }, _.teamSharingAllowlistRemove = function (e) {
    return this.request("team/sharing_allowlist/remove", e, "team", "api", "rpc", "team_info.write");
  }, _.teamTeamFolderActivate = function (e) {
    return this.request("team/team_folder/activate", e, "team", "api", "rpc", "team_data.content.write");
  }, _.teamTeamFolderArchive = function (e) {
    return this.request("team/team_folder/archive", e, "team", "api", "rpc", "team_data.content.write");
  }, _.teamTeamFolderArchiveCheck = function (e) {
    return this.request("team/team_folder/archive/check", e, "team", "api", "rpc", "team_data.content.write");
  }, _.teamTeamFolderCreate = function (e) {
    return this.request("team/team_folder/create", e, "team", "api", "rpc", "team_data.content.write");
  }, _.teamTeamFolderGetInfo = function (e) {
    return this.request("team/team_folder/get_info", e, "team", "api", "rpc", "team_data.content.read");
  }, _.teamTeamFolderList = function (e) {
    return this.request("team/team_folder/list", e, "team", "api", "rpc", "team_data.content.read");
  }, _.teamTeamFolderListContinue = function (e) {
    return this.request("team/team_folder/list/continue", e, "team", "api", "rpc", "team_data.content.read");
  }, _.teamTeamFolderPermanentlyDelete = function (e) {
    return this.request("team/team_folder/permanently_delete", e, "team", "api", "rpc", "team_data.content.write");
  }, _.teamTeamFolderRename = function (e) {
    return this.request("team/team_folder/rename", e, "team", "api", "rpc", "team_data.content.write");
  }, _.teamTeamFolderUpdateSyncSettings = function (e) {
    return this.request("team/team_folder/update_sync_settings", e, "team", "api", "rpc", "team_data.content.write");
  }, _.teamTokenGetAuthenticatedAdmin = function () {
    return this.request("team/token/get_authenticated_admin", null, "team", "api", "rpc", "team_info.read");
  }, _.teamLogGetEvents = function (e) {
    return this.request("team_log/get_events", e, "team", "api", "rpc", "events.read");
  }, _.teamLogGetEventsContinue = function (e) {
    return this.request("team_log/get_events/continue", e, "team", "api", "rpc", "events.read");
  }, _.usersFeaturesGetValues = function (e) {
    return this.request("users/features/get_values", e, "user", "api", "rpc", "account_info.read");
  }, _.usersGetAccount = function (e) {
    return this.request("users/get_account", e, "user", "api", "rpc", "sharing.read");
  }, _.usersGetAccountBatch = function (e) {
    return this.request("users/get_account_batch", e, "user", "api", "rpc", "sharing.read");
  }, _.usersGetCurrentAccount = function () {
    return this.request("users/get_current_account", null, "user", "api", "rpc", "account_info.read");
  }, _.usersGetSpaceUsage = function () {
    return this.request("users/get_space_usage", null, "user", "api", "rpc", "account_info.read");
  };

  var q = function (e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : m,
        r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : ".";
    return r ? (t !== m && void 0 !== d[e] && (e = d[e], r = "-"), "https://".concat(e).concat(r).concat(t, "/2/")) : "https://".concat(t, "/2/");
  },
      b = function () {
    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : h;
    return e !== h && (e = "meta-".concat(e)), "https://".concat(e, "/oauth2/authorize");
  },
      v = function () {
    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : m,
        t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : ".",
        r = "api";
    return e !== m && (r = d[r], t = "-"), "https://".concat(r).concat(t).concat(e, "/oauth2/token");
  };

  function w(e) {
    return JSON.stringify(e).replace(/[\u007f-\uffff]/g, g);
  }

  function k(e) {
    return new Date(Date.now() + 1000 * e);
  }

  function y() {
    return "undefined" != typeof WorkerGlobalScope && self instanceof WorkerGlobalScope || false || "undefined" != typeof window;
  }

  function A() {
    return "undefined" != typeof window;
  }

  function S() {
    return "undefined" != typeof WorkerGlobalScope && self instanceof WorkerGlobalScope;
  }

  function C(e) {
    return e.toString("base64").replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
  }

  var T,
      L,
      M,
      P = function (e) {
    !function (e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          writable: !0,
          configurable: !0
        }
      }), t && n(e, t);
    }(u, e);
    var r,
        i,
        o = (r = u, i = a(), function () {
      var e,
          t = s(r);

      if (i) {
        var n = s(this || _global).constructor;
        e = Reflect.construct(t, arguments, n);
      } else e = t.apply(this || _global, arguments);

      return c(this || _global, e);
    });

    function u(e, r, i) {
      var s;
      return t(this || _global, u), (s = o.call(this || _global, "Response failed with a ".concat(e, " code"))).name = "DropboxResponseError", s.status = e, s.headers = r, s.error = i, s;
    }

    return u;
  }(u(Error)),
      R = function e(r, i, s) {
    t(this || _global, e), (this || _global).status = r, (this || _global).headers = i, (this || _global).result = s;
  };

  function F(e) {
    return e.text().then(function (t) {
      var r;

      try {
        r = JSON.parse(t);
      } catch (e) {
        r = t;
      }

      throw new P(e.status, e.headers, r);
    });
  }

  function D(e) {
    return e.ok ? e.text().then(function (t) {
      var r;

      try {
        r = JSON.parse(t);
      } catch (e) {
        r = t;
      }

      return new R(e.status, e.headers, r);
    }) : F(e);
  }

  var G = ["legacy", "offline", "online"],
      U = ["code", "token"],
      x = ["none", "user", "team"],
      E = function () {
    function e(r) {
      t(this || _global, e), r = r || {}, A() ? (T = window.fetch.bind(window), L = window.crypto || window.msCrypto) : S() ? (T = self.fetch.bind(self), L = self.crypto) : (T = _nodeFetch, L = _crypto), M = "undefined" == typeof TextEncoder ? _util.TextEncoder : TextEncoder, (this || _global).fetch = r.fetch || T, (this || _global).accessToken = r.accessToken, (this || _global).accessTokenExpiresAt = r.accessTokenExpiresAt, (this || _global).refreshToken = r.refreshToken, (this || _global).clientId = r.clientId, (this || _global).clientSecret = r.clientSecret, (this || _global).domain = r.domain, (this || _global).domainDelimiter = r.domainDelimiter, (this || _global).customHeaders = r.customHeaders, (this || _global).dataOnBody = r.dataOnBody;
    }

    return i(e, [{
      key: "setAccessToken",
      value: function (e) {
        (this || _global).accessToken = e;
      }
    }, {
      key: "getAccessToken",
      value: function () {
        return (this || _global).accessToken;
      }
    }, {
      key: "setClientId",
      value: function (e) {
        (this || _global).clientId = e;
      }
    }, {
      key: "getClientId",
      value: function () {
        return (this || _global).clientId;
      }
    }, {
      key: "setClientSecret",
      value: function (e) {
        (this || _global).clientSecret = e;
      }
    }, {
      key: "getClientSecret",
      value: function () {
        return (this || _global).clientSecret;
      }
    }, {
      key: "getRefreshToken",
      value: function () {
        return (this || _global).refreshToken;
      }
    }, {
      key: "setRefreshToken",
      value: function (e) {
        (this || _global).refreshToken = e;
      }
    }, {
      key: "getAccessTokenExpiresAt",
      value: function () {
        return (this || _global).accessTokenExpiresAt;
      }
    }, {
      key: "setAccessTokenExpiresAt",
      value: function (e) {
        (this || _global).accessTokenExpiresAt = e;
      }
    }, {
      key: "setCodeVerifier",
      value: function (e) {
        (this || _global).codeVerifier = e;
      }
    }, {
      key: "getCodeVerifier",
      value: function () {
        return (this || _global).codeVerifier;
      }
    }, {
      key: "generateCodeChallenge",
      value: function () {
        var e,
            t = this || _global,
            r = new M().encode((this || _global).codeVerifier);
        if (A() || S()) return L.subtle.digest("SHA-256", r).then(function (r) {
          var i = btoa(String.fromCharCode.apply(null, new Uint8Array(r)));
          e = C(i).substr(0, 128), t.codeChallenge = e;
        });
        var i = L.createHash("sha256").update(r).digest();
        return e = C(i), (this || _global).codeChallenge = e, Promise.resolve();
      }
    }, {
      key: "generatePKCECodes",
      value: function () {
        var e;

        if (A() || S()) {
          var t = new Uint8Array(128),
              r = L.getRandomValues(t);
          e = C(btoa(r)).substr(0, 128);
        } else {
          e = C(L.randomBytes(128)).substr(0, 128);
        }

        return (this || _global).codeVerifier = e, this.generateCodeChallenge();
      }
    }, {
      key: "getAuthenticationUrl",
      value: function (e, t) {
        var r,
            i = this || _global,
            s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "token",
            n = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : null,
            a = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : null,
            o = arguments.length > 5 && void 0 !== arguments[5] ? arguments[5] : "none",
            u = arguments.length > 6 && void 0 !== arguments[6] && arguments[6],
            c = this.getClientId(),
            p = b((this || _global).domain);
        if (!c) throw Error("A client id is required. You can set the client id using .setClientId().");
        if ("code" !== s && !e) throw Error("A redirect uri is required.");
        if (!U.includes(s)) throw Error("Authorization type must be code or token");
        if (n && !G.includes(n)) throw Error("Token Access Type must be legacy, offline, or online");
        if (a && !(a instanceof Array)) throw Error("Scope must be an array of strings");
        if (!x.includes(o)) throw Error("includeGrantedScopes must be none, user, or team");
        return r = "code" === s ? "".concat(p, "?response_type=code&client_id=").concat(c) : "".concat(p, "?response_type=token&client_id=").concat(c), e && (r += "&redirect_uri=".concat(e)), t && (r += "&state=".concat(t)), n && (r += "&token_access_type=".concat(n)), a && (r += "&scope=".concat(a.join(" "))), "none" !== o && (r += "&include_granted_scopes=".concat(o)), u ? this.generatePKCECodes().then(function () {
          return r += "&code_challenge_method=S256", r += "&code_challenge=".concat(i.codeChallenge);
        }) : Promise.resolve(r);
      }
    }, {
      key: "getAccessTokenFromCode",
      value: function (e, t) {
        var r = this.getClientId(),
            i = this.getClientSecret();
        if (!r) throw Error("A client id is required. You can set the client id using .setClientId().");
        var s = v((this || _global).domain, (this || _global).domainDelimiter);
        if (s += "?grant_type=authorization_code", s += "&code=".concat(t), s += "&client_id=".concat(r), i) s += "&client_secret=".concat(i);else {
          if (!(this || _global).codeVerifier) throw Error("You must use PKCE when generating the authorization URL to not include a client secret");
          s += "&code_verifier=".concat((this || _global).codeVerifier);
        }
        e && (s += "&redirect_uri=".concat(e));
        return this.fetch(s, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }).then(function (e) {
          return D(e);
        });
      }
    }, {
      key: "checkAndRefreshAccessToken",
      value: function () {
        var e = this.getRefreshToken() && this.getClientId(),
            t = !this.getAccessTokenExpiresAt() || new Date(Date.now() + 300000) >= this.getAccessTokenExpiresAt(),
            r = !this.getAccessToken();
        return (t || r) && e ? this.refreshAccessToken() : Promise.resolve();
      }
    }, {
      key: "refreshAccessToken",
      value: function () {
        var e = this || _global,
            t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : null,
            r = this.getClientId(),
            i = this.getClientSecret();
        if (!r) throw Error("A client id is required. You can set the client id using .setClientId().");
        if (t && !(t instanceof Array)) throw Error("Scope must be an array of strings");
        var s = v((this || _global).domain, (this || _global).domainDelimiter),
            n = {
          headers: {
            "Content-Type": "application/json"
          },
          method: "POST"
        };

        if ((this || _global).dataOnBody) {
          var a = {
            grant_type: "refresh_token",
            client_id: r,
            refresh_token: this.getRefreshToken()
          };
          i && (a.client_secret = i), t && (a.scope = t.join(" ")), n.body = a;
        } else s += "?grant_type=refresh_token&refresh_token=".concat(this.getRefreshToken()), s += "&client_id=".concat(r), i && (s += "&client_secret=".concat(i)), t && (s += "&scope=".concat(t.join(" ")));

        return this.fetch(s, n).then(function (e) {
          return D(e);
        }).then(function (t) {
          e.setAccessToken(t.result.access_token), e.setAccessTokenExpiresAt(k(t.result.expires_in));
        });
      }
    }]), e;
  }(),
      B = "undefined" == typeof btoa ? function (e) {
    return Buffer.from(e).toString("base64");
  } : btoa;

  e.Dropbox = function () {
    function e(r) {
      t(this || _global, e), (this || _global).auth = (r = r || {}).auth ? r.auth : new E(r), (this || _global).fetch = r.fetch || (this || _global).auth.fetch, (this || _global).selectUser = r.selectUser, (this || _global).selectAdmin = r.selectAdmin, (this || _global).pathRoot = r.pathRoot, (this || _global).domain = r.domain || (this || _global).auth.domain, (this || _global).domainDelimiter = r.domainDelimiter || (this || _global).auth.domainDelimiter, (this || _global).customHeaders = r.customHeaders || (this || _global).auth.customHeaders, Object.assign(this || _global, _);
    }

    return i(e, [{
      key: "request",
      value: function (e, t, r, i, s) {
        switch (s) {
          case "rpc":
            return this.rpcRequest(e, t, r, i);

          case "download":
            return this.downloadRequest(e, t, r, i);

          case "upload":
            return this.uploadRequest(e, t, r, i);

          default:
            throw Error("Invalid request style: ".concat(s));
        }
      }
    }, {
      key: "rpcRequest",
      value: function (e, t, r, i) {
        var s = this || _global;
        return (this || _global).auth.checkAndRefreshAccessToken().then(function () {
          var e = {
            method: "POST",
            body: t ? JSON.stringify(t) : null,
            headers: {}
          };
          return t && (e.headers["Content-Type"] = "application/json"), s.setAuthHeaders(r, e), s.setCommonHeaders(e), e;
        }).then(function (t) {
          return s.fetch(q(i, s.domain, s.domainDelimiter) + e, t);
        }).then(function (e) {
          return D(e);
        });
      }
    }, {
      key: "downloadRequest",
      value: function (e, t, r, i) {
        var s = this || _global;
        return (this || _global).auth.checkAndRefreshAccessToken().then(function () {
          var e = {
            method: "POST",
            headers: {
              "Dropbox-API-Arg": w(t)
            }
          };
          return s.setAuthHeaders(r, e), s.setCommonHeaders(e), e;
        }).then(function (t) {
          return s.fetch(q(i, s.domain, s.domainDelimiter) + e, t);
        }).then(function (e) {
          return function (e) {
            return e.ok ? new Promise(function (t) {
              y() ? e.blob().then(function (e) {
                return t(e);
              }) : e.buffer().then(function (e) {
                return t(e);
              });
            }).then(function (t) {
              var r = JSON.parse(e.headers.get("dropbox-api-result"));
              return y() ? r.fileBlob = t : r.fileBinary = t, new R(e.status, e.headers, r);
            }) : F(e);
          }(e);
        });
      }
    }, {
      key: "uploadRequest",
      value: function (e, t, r, i) {
        var s = this || _global;
        return (this || _global).auth.checkAndRefreshAccessToken().then(function () {
          var e = t.contents;
          delete t.contents;
          var i = {
            body: e,
            method: "POST",
            headers: {
              "Content-Type": "application/octet-stream",
              "Dropbox-API-Arg": w(t)
            }
          };
          return s.setAuthHeaders(r, i), s.setCommonHeaders(i), i;
        }).then(function (t) {
          return s.fetch(q(i, s.domain, s.domainDelimiter) + e, t);
        }).then(function (e) {
          return D(e);
        });
      }
    }, {
      key: "setAuthHeaders",
      value: function (e, t) {
        if (e.split(",").length > 1) {
          var r = e.replace(" ", "").split(",");
          r.includes(l) && (this || _global).auth.getAccessToken() ? e = l : r.includes(f) && (this || _global).auth.getAccessToken() ? e = f : r.includes(p) && (e = p);
        }

        switch (e) {
          case p:
            if ((this || _global).auth.clientId && (this || _global).auth.clientSecret) {
              var i = B("".concat((this || _global).auth.clientId, ":").concat((this || _global).auth.clientSecret));
              t.headers.Authorization = "Basic ".concat(i);
            }

            break;

          case f:
          case l:
            (this || _global).auth.getAccessToken() && (t.headers.Authorization = "Bearer ".concat((this || _global).auth.getAccessToken()));
            break;

          case "noauth":
          case "cookie":
            break;

          default:
            throw Error("Unhandled auth type: ".concat(e));
        }
      }
    }, {
      key: "setCommonHeaders",
      value: function (e) {
        var t = this || _global;
        ((this || _global).selectUser && (e.headers["Dropbox-API-Select-User"] = (this || _global).selectUser), (this || _global).selectAdmin && (e.headers["Dropbox-API-Select-Admin"] = (this || _global).selectAdmin), (this || _global).pathRoot && (e.headers["Dropbox-API-Path-Root"] = (this || _global).pathRoot), (this || _global).customHeaders) && Object.keys((this || _global).customHeaders).forEach(function (r) {
          e.headers[r] = t.customHeaders[r];
        });
      }
    }]), e;
  }(), e.DropboxAuth = E, e.DropboxResponse = R, e.DropboxResponseError = P, Object.defineProperty(e, "__esModule", {
    value: !0
  });
});
export default exports;